import {
  StyledColumnContainer,
  StyledColumnsContainer,
  StyledContainer,
  StyledH3,
  StyledSpan,
  StyledUnderlinedH2
} from './styles'
import { injectIntl, intlShape } from 'react-intl'

import { Box } from 'grommet'
import Button from '../reusables/button'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import React from 'react'
import media from 'styled-media-query'
import shortid from 'shortid'
import styled from 'styled-components'

const StyledImageContainer = styled(Box)`
  width: 200px;
  height: 50px;
`

const StyledBoxContainer = styled(StyledColumnContainer)`
  height: 240px;
  margin: 10px 30px;
  padding: 0;
`

const StyledBoxColumnsContainer = styled(StyledColumnsContainer)`
 margin-top: 62px;
 justify-content: center;
`

const StyledInnerBoxContainer = styled(Box)`
  display: grid;
  justify-items: center;
`

const Boxes = ({ index, data, shade, intl }) => {
  const boxes = `boxes.boxes${index}`
  const title = intl.formatMessage({
    id: `${boxes}.title`,
  })
  const hasTitle = title !== 'null'
  const description = intl.formatMessage({
    id: `${boxes}.description.description`,
  })
  const hasDescription = description !== 'null'
  const isRichText =
    intl.formatMessage({
      id: `${boxes}.description.richText`,
    }) !== 'null'

  const buttons = intl.formatMessage({
    id: `${boxes}.buttons.number`,
  })
  const number = intl.formatMessage({
    id: `${boxes}.number`,
  })
  const totalColumns = intl.formatMessage({
    id: `${boxes}.columns`,
  })

  const backgroundImage = intl.formatMessage({
    id: `${boxes}.backgroundImage`,
  })

  return (
    <StyledContainer
      componentName="Boxes"
      data={data}
      shade={shade}
      backgroundImage={backgroundImage}
    >
      {hasTitle && <StyledUnderlinedH2>{title}</StyledUnderlinedH2>}
      {hasDescription && !isRichText ? (
        <StyledSpan>{description}</StyledSpan>
      ) : (
        hasDescription &&
        isRichText && (
          <StyledSpan dangerouslySetInnerHTML={{ __html: description }} />
        )
      )}

      <StyledBoxColumnsContainer
        columns={totalColumns}
        justify="center"
        align="center"
      >
        {Array.from(Array(Number(number)).keys()).map((column, index) => {
          const section = `${boxes}.box${index}`
          const boxTitle = intl.formatMessage({
            id: `${section}.title`,
          })
          const hasBoxTitle = boxTitle !== 'null'
          const boxDescription = intl.formatMessage({
            id: `${section}.description.description`,
          })
          const hasBoxDescription = boxDescription !== 'null'
          const isBoxRichText =
            intl.formatMessage({
              id: `${section}.description.richText`,
            }) !== 'null'

          const boxButtons = intl.formatMessage({
            id: `${section}.buttons.number`,
          })

          const boxImages = intl.formatMessage({
            id: `${section}.images.number`,
          })

          return (
            <StyledBoxContainer
              align="center"
              key={shortid.generate()}
              justify="center"
              round="1px"
              background={
                shade === 'light'
                  ? 'primaryGrey'
                  : shade === 'grey'
                  ? 'primaryWhite'
                  : 'primaryDark'
              }
              width={"273px"}
            >
              <StyledInnerBoxContainer>
                {boxImages > 0 && (
                  <Box
                    direction="row-responsive"
                    justify="center"
                    align="center"
                  >
                    {Array.from(Array(Number(boxImages)).keys()).map(i => {
                      const boxImage = `${section}.images.image${i}`
                      const imageLink = intl.formatMessage({
                        id: `${boxImage}.link`,
                      })
                      const hasLink = imageLink !== 'null'

                      const gif = intl.formatMessage({
                        id: `${boxImage}.gif`,
                      })
                      const isGif = gif !== 'null'
                      const iconPath = intl.formatMessage({
                        id: `${boxImage}.path`,
                      })
                      let imagePath = 'imagePath'
                      if (!isGif) {
                        imagePath = data[`${iconPath}`].childImageSharp.fixed
                      } else {
                        imagePath = require(`./gifs/${iconPath}.gif`)
                      }

                      return (
                        <StyledImageContainer key={shortid.generate()}>
                          {hasLink ? (
                            <a href={imageLink} target="_blank">
                              {isGif ? (
                                <StyledImageContainer>
                                  <img alt={iconPath} src={imagePath} />
                                </StyledImageContainer>
                              ) : (
                                <StyledImageContainer>
                                  <Img
                                    alt={iconPath}
                                    imgStyle={{ objectFit: 'contain' }}
                                    fixed={imagePath}
                                  />
                                </StyledImageContainer>
                              )}
                            </a>
                          ) : isGif ? (
                            <StyledImageContainer>
                              <img alt={iconPath} src={imagePath} />
                            </StyledImageContainer>
                          ) : (
                            <StyledImageContainer>
                              <Img
                                alt={iconPath}
                                imgStyle={{ objectFit: 'contain' }}
                                fixed={imagePath}
                              />
                            </StyledImageContainer>
                          )}
                        </StyledImageContainer>
                      )
                    })}
                  </Box>
                )}
                {hasBoxTitle && <StyledH3>{boxTitle}</StyledH3>}
                {hasBoxDescription && !isBoxRichText ? (
                  <StyledSpan>{boxDescription}</StyledSpan>
                ) : (
                  hasBoxDescription &&
                  isBoxRichText && (
                    <StyledSpan
                      dangerouslySetInnerHTML={{ __html: boxDescription }}
                    />
                  )
                )}
                {boxButtons > 0 && (
                  <Box
                    direction="row-responsive"
                    gap="medium"
                    pad={{ vertical: 'medium' }}
                  >
                    {Array.from(Array(Number(boxButtons)).keys()).map(i => {
                      const boxButton = `button${i}`
                      const boxButtonLabel = intl.formatMessage({
                        id: `${section}.buttons.${boxButton}.label`,
                      })
                      const boxButtonLink = intl.formatMessage({
                        id: `${section}.buttons.${boxButton}.link`,
                      })
                      return (
                        <Button
                          key={shortid.generate()}
                          label={boxButtonLabel}
                          link={boxButtonLink}
                          light={
                            shade === 'light' || shade === 'grey' ? true : false
                          }
                        />
                      )
                    })}
                  </Box>
                )}
              </StyledInnerBoxContainer>
            </StyledBoxContainer>
          )
        })}
      </StyledBoxColumnsContainer>
      {buttons > 0 && (
        <Box
          direction="row-responsive"
          gap="medium"
          pad={{ vertical: 'medium' }}
        >
          {Array.from(Array(Number(buttons)).keys()).map(i => {
            const button = `button${i}`
            const buttonLabel = intl.formatMessage({
              id: `${boxes}.buttons.${button}.label`,
            })
            const buttonLink = intl.formatMessage({
              id: `${boxes}.buttons.${button}.link`,
            })
            return (
              <Button
                key={shortid.generate()}
                label={buttonLabel}
                link={buttonLink}
                light={shade === 'light' || shade === 'grey' ? true : false}
              />
            )
          })}
        </Box>
      )}
    </StyledContainer>
  )
}

Boxes.propTypes = {
  data: PropTypes.object,
  shade: PropTypes.string,
  index: PropTypes.number,
  intl: intlShape.isRequired,
}

export default injectIntl(Boxes)
