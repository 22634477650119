import { Link, withIntl } from '../../i18n'
import { StaticQuery, graphql } from 'gatsby'
import { bannerIcon, fluidImage, logos, people } from '../../fragments/fragments'

import Banner from '../../components/reusables/banner'
import Boxes from '../../components/reusables/boxes'
import Layout from '../../components/layout'
import PartnerBoxes from '../../components/reusables/partnerBoxes'
import People from '../../components/reusables/people'
import React from 'react'

const Team = ({ data }) => (
  <Layout>
    <Banner data={data} shade={'light'} />
    <People index={0} data={data} shade={'light'} />
    <People index={1} data={data} shade={'grey'} />
    <PartnerBoxes index={0} data={data} shade={'light'} />
    <PartnerBoxes index={1} data={data} shade={'grey'} />
  </Layout>
)

export default withIntl(Team)

export const imagesQuery = graphql`
  query {
    bannerGlobe: file(relativePath: { eq: "hero/bannerGlobe@3x.png" }) {
      ...bannerIcon
    }
    lucas: file(relativePath: { eq: "people/lucas@3x.png" }) {
      ...people
    }
    alex: file(relativePath: { eq: "people/alex@3x.png" }) {
      ...people
    }
    george: file(relativePath: { eq: "people/george@3x.png" }) {
      ...people
    }
    garwin: file(relativePath: { eq: "people/garwin@3x.png" }) {
      ...people
    }
    gloria: file(relativePath: { eq: "people/gloria@3x.png" }) {
      ...people
    }
    michael: file(relativePath: { eq: "people/michael@3x.png" }) {
      ...people
    }
    michaelD: file(relativePath: { eq: "people/michaelD@3x.png" }) {
      ...people
    }
    tim: file(relativePath: { eq: "people/tim@3x.png" }) {
      ...people
    }
    violin: file(relativePath: { eq: "people/violin@3x.png" }) {
      ...people
    }
    ash: file(relativePath: { eq: "people/ash@3x.png" }) {
      ...people
    }
    amanda: file(relativePath: { eq: "people/amanda@3x.png" }) {
      ...people
    }
    frank: file(relativePath: { eq: "people/frank@3x.png" }) {
      ...people
    }
    malachi: file(relativePath: { eq: "people/malachi@3x.png" }) {
      ...people
    }
    marc: file(relativePath: { eq: "people/marc@3x.png" }) {
      ...people
    }
    mark: file(relativePath: { eq: "people/mark@3x.png" }) {
      ...people
    }
    linkedin: file(relativePath: { eq: "icons/linkedin@3x.png" }) {
      ...fluidImage
    }
    shortLastSection: file(relativePath: { eq: "hero/shortLastSection@2x.png" }) {
      childImageSharp {
        fluid(maxHeight: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    fivemiles: file(relativePath: { eq: "partners/fivemiles@3x.png" }) {
      childImageSharp {
        fixed(width: 154, height: 40) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    blockchainVentures: file(
      relativePath: { eq: "partners/blockchainVentures@3x.png" }
    ) {
      ...logos
    }
    linkvc: file(relativePath: { eq: "partners/linkvc@3x.png" }) {
      ...logos
    }
    collinstar: file(relativePath: { eq: "partners/collinstar@3x.png" }) {
      childImageSharp {
        fixed(width: 73) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    aware: file(relativePath: { eq: "partners/aware@3x.png" }) {
      ...logos
    }
    chainedbox: file(relativePath: { eq: "partners/chainedbox@3x.png" }) {
      ...logos
    }
    cybervein: file(relativePath: { eq: "partners/cybervein@3x.png" }) {
      childImageSharp {
        fixed(width: 99) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    hyperpay: file(relativePath: { eq: "partners/hyperpay@3x.png" }) {
      childImageSharp {
        fixed(width: 200) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    yeewhy: file(relativePath: { eq: "counsel/yeewhy@3x.png" }) {
      childImageSharp {
        fixed(width: 81, height: 64) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    deloitte: file(relativePath: { eq: "counsel/deloitte@3x.png" }) {
      childImageSharp {
        fixed(width: 136, height: 27) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    lockeLord: file(relativePath: { eq: "counsel/lockeLord@3x.png" }) {
      childImageSharp {
        fixed(width: 84, height: 59) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
  }
`
