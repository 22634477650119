import {
  StyledColumnContainer,
  StyledColumnsContainer,
  StyledContainer,
  StyledH2,
  StyledH3,
  StyledP,
  StyledSpan,
  StyledUnderlinedH2,
} from './styles'
import { injectIntl, intlShape } from 'react-intl'

import { Box } from 'grommet'
import Button from '../reusables/button'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import React from 'react'
import media from 'styled-media-query'
import shortid from 'shortid'
import styled from 'styled-components'

const StyledMemberContainer = styled(Box)`
  display: grid;
  justify-items: center;
  align-items: center;
  grid-template-rows: auto;
  grid-template-columns: 100%;
  margin-bottom: 64px;
  grid-template-areas:
    'image'
    'content';
  ${media.greaterThan('medium')`
    grid-template-columns: auto auto;
    grid-template-rows: 100%;
    grid-template-areas: 'image content';
    grid-column-gap: 35px;
  `}
`
const StyledImageContainer = styled(Box)`
  grid-area: image;
  align-items: center;
  min-width: 180px;
  min-height: 180px;
`

const StyledMember = styled(Box)`
  grid-area: content;
  align-content: center;
  justify-content: center;

  p {
    margin-top: 0;
    font-size: 16px;
  }
`

const StyledMemberName = styled(StyledH2)``

const StyledMemberNameWithTitle = styled(StyledMemberName)`
  margin-bottom: 0px;
`
const StyledMemberDescription = styled(StyledSpan)`
  font-size: 16px;
`;

const StyledName = styled(Box)`
  color: ${props => props.theme.colors.primaryDark};
`

const People = ({ index, data, shade, intl }) => {
  const teamMembers = `teamMembers.teamMembers${index}`
  const title = intl.formatMessage({
    id: `${teamMembers}.title`,
  })
  const hasTitle = title !== 'null'
  const description = intl.formatMessage({
    id: `${teamMembers}.description.description`,
  })
  const hasDescription = description !== 'null'
  const isRichText =
    intl.formatMessage({
      id: `${teamMembers}.description.richText`,
    }) !== 'null'

  const members = intl.formatMessage({
    id: `${teamMembers}.number`,
  })

  return (
    <StyledContainer componentName="People" shade={shade}>
      {hasTitle && <StyledUnderlinedH2>{title}</StyledUnderlinedH2>}
      {hasDescription && !isRichText ? (
        <StyledP>{description}</StyledP>
      ) : (
        hasDescription &&
        isRichText && (
          <StyledSpan dangerouslySetInnerHTML={{ __html: description }} />
        )
      )}
      {Array.from(Array(Number(members)).keys()).map(i => {
        const member = `${teamMembers}.teamMember${i}`
        const memberName = intl.formatMessage({
          id: `${member}.name`,
        })
        const hasMemberName = memberName !== 'null'
        const memberTitle = intl.formatMessage({
          id: `${member}.title`,
        })
        const hasMemberTitle = memberTitle !== 'null'
        const memberDescription = intl.formatMessage({
          id: `${member}.description.description`,
        })
        const hasMemberDescription = memberDescription !== 'null'
        const isMemberRichText =
          intl.formatMessage({
            id: `${member}.description.richText`,
          }) !== 'null'

        const linkedin = intl.formatMessage({
          id: `${member}.linkedin`,
        })
        const hasLinkedin = linkedin !== 'null'
        const iconPath = intl.formatMessage({
          id: `${member}.image`,
        })
        let hasIcon = false
        let imagePath = 'imagePath'
        if (iconPath !== 'null') {
          imagePath = data[`${iconPath}`].childImageSharp.fluid
          hasIcon = true
        }

        return (
          <StyledMemberContainer>
            {hasIcon && (
              <StyledImageContainer>
                <Img
                  alt={iconPath}
                  style={{ width: '180px', height: '180px' }}
                  fluid={imagePath}
                />
              </StyledImageContainer>
            )}
            <StyledMember>
              {hasMemberName && (
                <StyledName direction="row" align="center" gap="small">
                  {hasMemberTitle ? (
                    <StyledMemberNameWithTitle>
                      {memberName}
                    </StyledMemberNameWithTitle>
                  ) : (
                    <StyledMemberName>{memberName}</StyledMemberName>
                  )}
                  {hasLinkedin && (
                    <a href={linkedin}>
                      <Img
                        alt={iconPath}
                        style={{ width: '14px', height: '14px' }}
                        fluid={data.linkedin.childImageSharp.fluid}
                      />
                    </a>
                  )}
                </StyledName>
              )}
              {hasMemberTitle && (
                <StyledP styled={{ 'margin-top': '0' }}>{memberTitle}</StyledP>
              )}
              {hasMemberDescription && !isMemberRichText ? (
                <StyledMemberDescription>{memberDescription}</StyledMemberDescription>
              ) : (
                hasMemberDescription &&
                isMemberRichText && (
                  <StyledMemberDescription dangerouslySetInnerHTML={{ __html: memberDescription }} />
                )
              )}
            </StyledMember>
          </StyledMemberContainer>
        )
      })}
    </StyledContainer>
  )
}

People.propTypes = {
  data: PropTypes.object,
  shade: PropTypes.string,
  index: PropTypes.number,
  intl: intlShape.isRequired,
}

export default injectIntl(People)
